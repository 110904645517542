import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "エムスリー・メドレー合同勉強会開催しました（MedNightTokyo#1 レポート）",
  "date": "2016-12-26T09:34:44.000Z",
  "slug": "entry/2016/12/26/183444",
  "tags": ["medley"],
  "hero": "./2016_12_26.png",
  "heroAlt": "mednight"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、エンジニア兼`}<a parentName="p" {...{
        "href": "https://www.target-darts.jp/shopdetail/000000000022/"
      }}>{`ダーツプロ`}</a>{`の徐です。`}</p>
    <p>{`2016 年 11 月 14 日（水）に株式会社`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%C9%A5%EA%A5%B3%A5%E0"
      }}>{`ドリコム`}</a>{`さんのイベントスペースをお借りして、`}<a parentName="p" {...{
        "href": "https://corporate.m3.com/"
      }}>{`エムスリー株式会社`}</a>{`さんとの合同勉強会を開催しました。
「医療系サービスにおけるエンジニア運用裏話」というテーマで、泥臭い・苦労した話やそれをどう工夫したかについて発表しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161222/20161222141637.jpg",
        "alt": "f:id:yamadagenki:20161222141637j:plain",
        "title": "f:id:yamadagenki:20161222141637j:plain"
      }}></img></p>
    <p>{`発表の様子`}</p>
    <p>{`"開発"と言えば、「新規機能追加」を想像される方が多いのではないでしょうか。
しかし、実際現場で働くエンジニアは、新規開発をするのと並行して、安定したサービスを提供し続けるために日々努力しています。
大勢の人々が"当たり前に動くサービス"を実現するために、一生懸命になっているのです。
今回の勉強会はこうした各社の苦い話や知見を持ち寄った会となりました。`}</p>
    <p>{`当日は各社 2 名ずつ、15 分弱の LT を行いました。 メドレーの発表内容を少しだけご紹介します。`}</p>
    <h1>{`1 人開発体制における運用と工夫~介護のほんね奮闘記~`}</h1>
    <p>{`まずは私、徐より介護施設の口コミサイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」 を、エンジニア 1 人でどう運用しているかについてご紹介しました。
１人で運用する際の苦労は何か、そして１人で運用する過程で大事なこととは何かについて紹介していきました。
必ずしもシステム的な工夫だけではなく、周りのメンバーにうまく協力をしてもらう姿勢について、日々の心がけなどを話しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161222/20161222141632.jpg",
        "alt": "f:id:yamadagenki:20161222141632j:plain",
        "title": "f:id:yamadagenki:20161222141632j:plain"
      }}></img></p>
    <p>{`人前での発表が不慣れで緊張している私…`}</p>
    <p>{`発表資料は`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/1ren-kai-fa-ti-zhi-niokeruyun-yong-togong-fu-jie-hu-falsehonnefen-dou-ji"
      }}>{`こちら`}</a></p>
    <h1>{`オンライン病気事典 MEDLEY の 地味だけど重要な 運用の話`}</h1>
    <p>{`そして、オンライン病気事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」を担当する`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/37518"
      }}>{`竹内`}</a>{`からは、運用の裏側として`}<strong parentName="p">{`小規模チームに起きる問題`}</strong>{`とそれを解決する方法についての紹介がありました。
今回は、開発ばかりしているとつい疎かになりがちな`}<strong parentName="p">{`「運用手順書」`}</strong>{`にスポットを当て、手順書を作成している際に気をつけているポイントについて解説しました。
発表資料は`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/onrainbing-qi-shi-dian-medleyfalse-di-wei-taketozhong-yao-na-yun-yong-falsehua"
      }}>{`こちら`}</a></p>
    <h1>{`まとめ`}</h1>
    <p>{`今回の勉強会では、普段なかなか取り扱われない"運用"をテーマにし、苦労した点やそれを工夫した点など”地味だけど大事な”ことについて、各社のノウハウを話し合うことができました。`}</p>
    <p>{`エンジニアの仕事は、新規機能開発より運用のほうが割合が高いことから、日々の業務のなかで共感できる・役に立つ話をできるのでは、と考え勉強会を企画しました。
発表中に「そうだよね」と頷く参加者の方もいらっしゃり、さらに終了後の懇親会では「すごい共感しました…」といった感想をいただくなど、参加者の方にも楽しんでいただけた企画となったのではと感じています。`}</p>
    <p>{`運用は、システム的な自動化の工夫ももちろんですが、人が人力作業でやるものもあります。
エムスリーさんからも実状やノウハウを（ぶっちゃけ話も織り交ぜながら）伺えましたが、いずれも運用においてオンライン・オフラインを問わず`}<strong parentName="p">{`「コミュニケーション」（人間関係）`}</strong>{`が大事なんだなと感じました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161222/20161222141639.jpg",
        "alt": "f:id:yamadagenki:20161222141639j:plain",
        "title": "f:id:yamadagenki:20161222141639j:plain"
      }}></img></p>
    <p>{`勉強会後の懇親会の様子。共感した・参考になったという話が多く飛び交いました。`}</p>
    <p>{`今後もこうした実際の現場で役に立つ勉強会を定期的に開催していく予定です。 MedNightTokyo の Connpass グループで最新情報を発信してまいりますので、今回は残念ながら参加できなかった方も是非フォローをお願いします。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MedNight Tokyo" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fmednight.connpass.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://mednight.connpass.com/">mednight.connpass.com</a></cite>
    <p>{`メドレーブログでも、勉強会情報を掲載していくので、ブログも引き続きチェックしてくださいね。`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`メドレー公式 Facebook ページ`}</a>{`に「いいね！」していただけると、ブログの最新情報をフォローできます`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      